<template>
  <div class="popup_wrap popup_wrap_pdf" style="width:800px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">Drop Off Charge</h1>
      <div class="content_box">
        <div class="clearfix">
          <div class="float_left text_center col_10 pl05" style="max-height:600px;overflow-y:auto">
            <vue-pdf-app
              v-if="fileType == 'PDF'"
              style="height: 600px;"
              :pdf="fileUrl"
              :config="pdfConfig.config"
              theme="light"
            ></vue-pdf-app>
            <img v-else-if="fileType == 'IMG'" :src="fileUrl">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import containerReturn from '@/api/rest/trans/containerReturn'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  name: 'MyRequestDocPop',
  components: {
    vuePdfApp: () => import('vue-pdf-app')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      popName: '',
      fileUrl: '',
      fileType: '',
      atchFileSeq: '',
      pdfConfig: {
        config: {},
        idConfig: {}
      }
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.pdfConfig = this.getPdfConfig()
      if (this.$props.parentInfo !== undefined) {
        this.setItems(this.$props.parentInfo)
      }
    },
    async setItems (rvo) {
      const formData = { reqRno: rvo.reqRno, atchFileSeq: 1 }
      const resultName = await containerReturn.reqfileName(formData)
      await containerReturn.reqDownload(formData).then(async res => {
          const vo = res.data
          if (!vo) {
            await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CMBA380.023') })
            this.$emit('close')
          } else {
            if (resultName.data.toLowerCase().indexOf('.pdf') > -1) {
              this.fileType = 'PDF'
              const pdfUrl = window.URL.createObjectURL(vo)
              this.fileUrl = pdfUrl
              setTimeout(function () {
                window.URL.revokeObjectURL(pdfUrl)
              }, 100)
            } else {
              let image = new Image()
              image.src = 'data:image/png;base64,' + vo
              this.fileType = image.src
            }
          }
      })
    },
    getPdfConfig () {
      const config = { toolbar: false }
      return {
        config
      }
    }
  }
}
</script>

<style>
body {
  min-width:100% !important;
}
</style>
