var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap popup_wrap_pdf",
      staticStyle: { width: "800px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("Drop Off Charge")]),
        _c("div", { staticClass: "content_box" }, [
          _c("div", { staticClass: "clearfix" }, [
            _c(
              "div",
              {
                staticClass: "float_left text_center col_10 pl05",
                staticStyle: { "max-height": "600px", "overflow-y": "auto" },
              },
              [
                _vm.fileType == "PDF"
                  ? _c("vue-pdf-app", {
                      staticStyle: { height: "600px" },
                      attrs: {
                        pdf: _vm.fileUrl,
                        config: _vm.pdfConfig.config,
                        theme: "light",
                      },
                    })
                  : _vm.fileType == "IMG"
                  ? _c("img", { attrs: { src: _vm.fileUrl } })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }