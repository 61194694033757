import Send from '../../trans.client.js'

export default {
  //반납 > 반납 조회
  getContainerReturn (formData) {
    return Send({
      method: 'get',
      url: '/trans/container-return',
      params: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  //반납 > 변경요청 팝업(리스트)
  returnChangeList (formData) {
    return Send({
      method: 'get',
      url: '/trans/container-return/return-site-change-popup?cntrNoArr=' + formData.cntrNoArr,
      params: {
        bankYn: formData.bankYn,
        bgkRtnYn: formData.bgkRtnYn,
        blNo: formData.blNo,
        cntrNo: formData.cntrNo,
        cntrOwn: formData.cntrOwn,
        cntrSzCd: formData.cntrSzCd,
        cntrTypCd: formData.cntrTypCd,
        cyCd: formData.cyCd,
        cyEnm: formData.cyEnm,
        detAmt: formData.detAmt,
        detDt: formData.detDt,
        dropKrw: formData.dropKrw,
        freeDds: formData.freeDds,
        picEnm: formData.picEnm,
        plcCd: formData.plcCd,
        telNo: formData.telNo,
        leaseCyCnt: formData.leaseCyCnt
      }
    }).catch((err) => {
      console.log(err)
    })
  },
  //반납 > 변경요청 로직
  returnChangeRequest (formData) {
    return Send({
      method: 'post',
      url: '/trans/container-return/return-site-change-popup',
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  //반납 > 변경요청 로직(BEC, SKY, CLI))
  returnChangeRequestEtc (formData) {
    return Send({
      method: 'post',
      url: '/trans/container-return/return-site-change-popup/etc',
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  //반납 > 요청 취소
  returnChangeCancel (formData) {
    return Send({
      method: 'put',
      url: '/trans/container-return/' + formData.reqRno + '/cancel',
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  //인보이스 요청서 다운로드(파일명)
  reqfileName (formData) {
  return Send({
    method: 'get',
    url: '/trans/container-return/return-invoice-download/name/' + formData.reqRno + '/' + formData.atchFileSeq
    }).catch((err) => {
      console.log(err)
    })
  },
  //인보이스 요청서 다운로드
  reqDownload (formData) {
    return Send({
      method: 'get',
      url: '/trans/container-return/return-invoice-download/' + formData.reqRno + '/' + formData.atchFileSeq,
      responseType: 'blob'
    }).catch((err) => {
      console.log(err)
    })
  }
}
